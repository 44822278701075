<template>
  <section class="views">
    <!-- header -->
    <headers select="2"></headers>

    <!-- banner  -->
    <div class="posters">
      <van-image
        width="100%"
        :src="require('@/assets/image/hodgepodge/pcbanner.png')"
      />
      <div class="posters_ewm" v-if="false">
        <div class="ewm">
          <div class="ewm2">
            <div class="ewm3">
              <img :src="require('@/assets/image/csewm.png')" alt="" />
            </div>
            <div class="ewm3_value">掃碼報名比賽</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="viewsBoxTab">
        <div class="viewsTab">
          <ul>
            <li
              v-for="(item, index) in gradeColumns"
              :key="index"
              @click="tabBut(index)"
              :class="select === index ? 'select' : ''"
            >
              <div>{{ item.name }}</div>
              <div>({{ item.fzhu }})</div>
            </li>
          </ul>
        </div>
      </div>

      <div class="viewsList">
        <ul class="animate__animated animate__bounceInLeft" v-if="select === 0">
          <li
            v-for="(item, index) in pdfList"
            :key="index"
            @click="pdfBut(item)"
          >
            {{ item.name }}
          </li>
        </ul>

        <ul class="animate__animated animate__bounceInLeft" v-if="select === 1">
          <li
            v-for="(item, index) in pdfList"
            :key="index"
            @click="pdfBut(item)"
          >
            {{ item.name }}
          </li>
        </ul>
        <ul class="animate__animated animate__bounceInLeft" v-if="select === 2">
          <li
            v-for="(item, index) in pdfList"
            :key="index"
            @click="pdfBut(item)"
          >
            {{ item.name }}
          </li>
        </ul>
        <ul class="animate__animated animate__bounceInLeft" v-if="select === 3">
          <li
            v-for="(item, index) in pdfList"
            :key="index"
            @click="pdfBut(item)"
          >
            {{ item.name }}
          </li>
        </ul>
        <ul class="animate__animated animate__bounceInLeft" v-if="select === 4">
          <li
            v-for="(item, index) in pdfList"
            :key="index"
            @click="pdfBut(item)"
          >
            {{ item.name }}
          </li>
        </ul>
        <ul class="animate__animated animate__bounceInLeft" v-if="select === 5">
          <li
            v-for="(item, index) in pdfList"
            :key="index"
            @click="pdfBut(item)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
   
  <script>
import headers from '@/components/header/pc_header'
export default {
  name: 'signup',
  data() {
    return {
      anshow: true,
      gradeColumns: [
        {
          name: '幼稚園初級組',
          fzhu: 'K1-K2'
        },
        {
          name: '幼稚園高級組',
          fzhu: 'K3'
        },
        {
          name: '小學初級組',
          fzhu: 'P1-P2'
        },
        {
          name: '小學中級組',
          fzhu: 'P3-P4'
        },
        {
          name: '小學高級組',
          fzhu: 'P5-P6'
        },
        {
          name: '初中組',
          fzhu: 'S1-S3'
        }
      ],
      pdf: {
        new0: [
          {
            name: '初賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/75d75c45ac24ae3f3dcfc8f08eb02767.pdf'
          },
          {
            name: '複賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/63c211cefe7ab52c4efbd6f207fbde07.pdf'
          },
          {
            name: '總決賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-10/51bc02fcf44671b21d6526cfe4a32bce.pdf'
          }
        ],
        new1: [
          {
            name: '初賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/95c6968617769eb9ddd3838756c19606.pdf'
          },
          {
            name: '複賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/1166762db20b95953256690d8587b112.pdf'
          },
          {
            name: '總決賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-10/a75f089212687eee2ac6184b121edb60.pdf'
          }
        ],
        new2: [
          {
            name: '初賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/27ea08230b9db0af8c94793037914650.pdf'
          },
          {
            name: '複賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/e6792b0eb54edf81c5fcb6d8fec335da.pdf'
          },
          {
            name: '總決賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-10/2100190c81c03b4b9abfefc48c176230.pdf'
          }
        ],
        new3: [
          {
            name: '初賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/fd6cd03d7ca997e042bee05533d8e1aa.pdf'
          },
          {
            name: '複賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/7bfa6848f4e16beb2195bf80e3498631.pdf'
          },
          {
            name: '總決賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-10/f8d2c4c0acb27aba6dbb85249c616748.pdf'
          }
        ],
        new4: [
          {
            name: '初賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/f088ae0230a74c1432df1b5e8b68d204.pdf'
          },
          {
            name: '複賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/e9cc2f983e7bcd0191a3d1ac224849ff.pdf'
          },
          {
            name: '總決賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-10/ddad94f9188f52083fc4b2c0345180d2.pdf'
          }
        ],
        new5: [
          {
            name: '初賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/179f6aae167e78501ebd0a52fa371c77.pdf'
          },
          {
            name: '複賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/85f57c34d273e567d8afe116663a7cd4.pdf'
          },
          {
            name: '總決賽誦材下載',
            url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-10/5c5d177ad944dbcd83c0c6d2c43164c7.pdf'
          }
        ]
      },
      pdfList: [],
      select: 0
    }
  },
  computed: {},
  components: {
    headers
  },
  // watch: {
  //   select(val){
  //     if(val){
  //       this.anshow = false;
  //       console.log(val);
  //       this.pdfList = [];
  //       this.pdfList = this.pdf[`new${val}`];
  //       this.anshow = true;
  //     }
  //   }
  // },
  created() {
    this.pdfList = this.pdf[`new0`]
  },
  mounted() {},
  methods: {
    getPdf(e) {
      this.$axios({
        url: e.url,
        method: 'get',
        responseType: 'blob'
      })
        .then((response) => {
          this.$toast.clear()
          this.$toast.success('開始下載')
          // 将文件流转化为 Blob 对象，并创建 a 标签进行下载
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const downloadElement = document.createElement('a')
          const href = window.URL.createObjectURL(blob) // 创建下载链接
          downloadElement.href = href
          downloadElement.download = e.name // 文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 下载
          document.body.removeChild(downloadElement) // 下载完成后移除元素
          window.URL.revokeObjectURL(href) // 释放占用的内存空间
        })
        .catch((error) => {
          this.$toast.clear()
          console.error(error) // 处理异常情况
        })
    },
    pdfBut(e) {
      if (e.url != '') {
        // this.getPdf(e);
        window.open(e.url, '_blank')
      } else {
        this.$dialog
          .alert({
            title: '提示',
            message: '總決賽誦材暫未開放下載',
            confirmButtonText: '確認'
          })
          .then(() => {
            // on close
          })
      }
    },
    tabBut(e) {
      this.select = e
      this.pdfList = this.pdf[`new${e}`]
    }
  }
}
</script>
  
  <style lang="scss" scoped>
.views {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background: #ff782f;

  .posters {
    width: 100%;
    display: flex;
    position: relative;

    img {
      width: 100%;
    }

    .posters_ewm {
      position: absolute;
      width: 1024px;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: right;

      .ewm {
        width: 161px;
        height: 189px;
        background: rgba(255, 255, 255, 0.63);
        border-radius: 16px 16px 16px 16px;
        padding: 8px;
        box-sizing: border-box;

        .ewm2 {
          width: 100%;
          height: 100%;
          padding: 6px;
          background: #fdcf1d;
          border-radius: 12px 12px 12px 12px;
          box-sizing: border-box;

          .ewm3 {
            width: 132px;
            height: 132px;
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            margin-bottom: 6px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .ewm3_value {
            font-size: 16px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #323232;
            line-height: 19px;
            text-align: center;
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    margin: auto;

    .viewsBoxTab {
      width: 100%;
      position: relative;
      background: #d34a00;

      .viewsTab {
        width: 100%;
        max-width: 1024px;
        margin: auto;
        box-sizing: border-box;
        display: flex;

        ul {
          width: 100%;
          height: 64px;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          li {
            width: 152px;
            height: 100%;
            display: flex;
            align-content: center;
            align-items: center;
            flex-wrap: wrap;
            font-family: PingFang SC-Semibold, PingFang SC;
            line-height: 20px;
            flex: 0 0 auto;
            background: #d34a00;
            cursor: pointer;

            div {
              width: 100%;
            }

            div:nth-child(1) {
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
            }

            div:nth-child(2) {
              font-size: 11px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
            }
          }

          .select {
            font-size: 16px;
            font-weight: 600;
            color: #903300;
            padding: 8px 0;
            background: url('~@/assets/image/hodgepodge/tabbuttom.png')
              no-repeat;
            background-size: 100% 100%;

            div:nth-child(1) {
              font-size: 16px !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600 !important;
              color: #903300;
            }

            div:nth-child(2) {
              font-size: 13px !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600 !important;
              color: #903300;
            }
          }
        }
      }
    }

    .viewsList {
      width: 100%;
      max-width: 1024px;
      margin: auto;
      margin-top: 64px;
      padding: 0.32rem;
      box-sizing: border-box;

      ul {
        width: 100%;

        li {
          width: 640px;
          height: 124px;
          margin: auto;
          background: #fff8de;
          box-shadow: 0px 0px 4px 0px rgba(126, 56, 0, 0.26);
          border: 4px solid #fff0e1;
          border-radius: 16px;
          margin-bottom: 32px;
          font-size: 24px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ff782f;
          line-height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>