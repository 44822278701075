<template>
  <section class="views">
    <headers select="2"></headers>
    <div class="views_content">
      <div class="views_banner">
        <img :src="require('@/assets/image/hodgepodge/h5banner.png')" alt="" />
      </div> 
      <div class="viewsBoxTab">
        <div class="viewsTab" ref="timeSlide">
          <ul>
            <li  v-for="(item,index) in gradeColumns" :key="index" @click="tabBut(index)" :class="select===index?'select':''" :ref="`tab${index}Ref`">
              <div>{{item.name}}</div>
              <div>({{item.fzhu}})</div>
            </li> 
          </ul>
        </div>
      </div>
     

      <div class="viewsList">
        <transition name="van-slide-left"> 
          <ul>
            <li v-for="(item,index) in pdfList" :key="index" @click="pdfBut(item)">{{item.name}}</li> 
          </ul>
      </transition>
          
      </div>
    </div> 
  </section>
</template>

<script>
import headers from "@/components/header/h_header";
const isIOS = /(iPhone|iPad|iPod|iOS)/.test(navigator.userAgent)
export default {
  name: "views",
  data() {
    return { 
      gradeColumns:[
        {
          name:'幼稚園初級組',
          fzhu:'K1-K2',
        },
        {
          name:'幼稚園高級組',
          fzhu:'K3',
        },
        {
          name:'小學初級組',
          fzhu:'P1-P2',
        },
        {
          name:'小學中級組',
          fzhu:'P3-P4',
        },
        {
          name:'小學高級組',
          fzhu:'P5-P6',
        },
        {
          name:'初中組',
          fzhu:'S1-S3',
        }
      ] ,
      pdf:{
        new0:[
          {
            name:'初賽誦材下載',
            url:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/75d75c45ac24ae3f3dcfc8f08eb02767.pdf',
          },
          {
            name:'複賽誦材下載',
            url:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/63c211cefe7ab52c4efbd6f207fbde07.pdf',
          },
          {
            name:'總決賽誦材下載',
            url:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-10/51bc02fcf44671b21d6526cfe4a32bce.pdf',
          }
        ],
        new1:[
          {
            name:'初賽誦材下載',
            url:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/95c6968617769eb9ddd3838756c19606.pdf',
          },
          {
            name:'複賽誦材下載',
            url:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/1166762db20b95953256690d8587b112.pdf',
          },
          {
            name:'總決賽誦材下載',
            url:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-10/a75f089212687eee2ac6184b121edb60.pdf',
          }
        ],
        new2:[
          {
            name:'初賽誦材下載',
            url:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/27ea08230b9db0af8c94793037914650.pdf',
          },
          {
            name:'複賽誦材下載',
            url:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/e6792b0eb54edf81c5fcb6d8fec335da.pdf',
          },
          {
            name:'總決賽誦材下載',
            url:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-10/2100190c81c03b4b9abfefc48c176230.pdf',
          }
        ],
        new3:[
          {
            name:'初賽誦材下載',
            url:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/fd6cd03d7ca997e042bee05533d8e1aa.pdf',
          },
          {
            name:'複賽誦材下載',
            url:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/7bfa6848f4e16beb2195bf80e3498631.pdf',
          },
          {
            name:'總決賽誦材下載',
            url:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-10/f8d2c4c0acb27aba6dbb85249c616748.pdf',
          }
        ],
        new4:[
          {
            name:'初賽誦材下載',
            url:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/f088ae0230a74c1432df1b5e8b68d204.pdf',
          },
          {
            name:'複賽誦材下載',
            url:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/e9cc2f983e7bcd0191a3d1ac224849ff.pdf',
          },
          {
            name:'總決賽誦材下載',
            url:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-10/ddad94f9188f52083fc4b2c0345180d2.pdf',
          }
        ],
        new5:[
          {
            name:'初賽誦材下載',
            url:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/179f6aae167e78501ebd0a52fa371c77.pdf',
          },
          {
            name:'複賽誦材下載',
            url:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-08/85f57c34d273e567d8afe116663a7cd4.pdf',
          },
          {
            name:'總決賽誦材下載',
            url:'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2023-10/5c5d177ad944dbcd83c0c6d2c43164c7.pdf',
          }
        ], 
      },
      pdfList:[],
      select:0,
    };
  },
  computed: {},
  components: {
    headers,
  },
  // watch: {
  //   select(val){
  //     if(val){
  //       console.log(val);
  //       this.pdfList = [];
  //       this.pdfList = this.pdf[`new${val}`]
  //     }
  //   }
  // },
  created() {
    this.pdfList = this.pdf[`new0`]
  },
  mounted() {},
  methods: {
    getPdf(e){
      this.$axios({
			  url: e.url,
			  method: 'get',
			  responseType: 'blob',
			})
			.then(response => {
        this.$toast.clear();
        // this.$toast.success('開始下載'); 
        // 将文件流转化为 Blob 对象，并创建 a 标签进行下载
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const downloadElement = document.createElement('a');
        const href = window.URL.createObjectURL(blob); // 创建下载链接
        downloadElement.href = href;
        downloadElement.download = e.name; // 文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); // 下载
        document.body.removeChild(downloadElement); // 下载完成后移除元素
        window.URL.revokeObjectURL(href); // 释放占用的内存空间
      }).catch(error => {
        this.$toast.clear();
        console.error(error); // 处理异常情况
      }); 
    },
    pdfBut(e){
      if(e.url != ''){
        let a = document.createElement('a')
        a.href = e.url
        a.click()
        // if(isIOS){
        // this.getPdf(e);
        // let a = document.createElement('a')
        // a.href = e.url
        // a.click()
        // }else{
        //   let url = 'https://chineserd.cn/h5/html/regulations/index.html?url=' + e.url
        //   let a = document.createElement('a')
        //   a.href = url
        //   a.click()
        // }

      }else{
        this.$dialog.alert({
		title: '提示',
		message: '總決賽誦材暫未開放下載',
		confirmButtonText:'確認',
		}).then(() => {
			// on close
		}); 
      }
    },
    tabBut(e) {
      this.select = e;
      this.pdfList = this.pdf[`new${e}`]
      // 触发滑动方法
      this.scrollLeftTo(e);
    },
    // 滑动
    scrollLeftTo(name) {
      const ref = `tab${name}Ref`;
      const nav = this.$refs.timeSlide;
      console.log("额:", this.$refs.timeSlide.offsetWidth);
      const title = this.$refs[ref][0];
      // 计算位移偏差
      const to = title.offsetLeft - (nav.offsetWidth - title.offsetWidth) / 2;
      nav.scrollLeft = to;
    },
  },
};
</script>

<style lang="scss" scoped>
.views {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(225deg, #ff8310 0%, #fe840f 100%);
  .views_content::-webkit-scrollbar {
      display: none;
    }
  .views_content {
    width: 100%;
    height: calc(100% - 0.92rem); 
    overflow: scroll;
    
    .views_banner {
      width: 100%;
      display: grid;

      img {
        width: 100%;
      }
    }

    .viewsTab::-webkit-scrollbar {
      display: none;
    }

    .viewsBoxTab{
      width: 100%;
    }

    .viewsTab{
      width: 100%;  
      padding: 0.16rem 0;
      box-sizing: border-box; 
      overflow-y: scroll;
      margin-top: -6%;

      ul{
        width: 100%;
        height: 1.28rem;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        

        li{
          width: 3.14rem;
          height: 100%; 
          display: flex;
          align-content: center;
          align-items: center; 
          flex-wrap: wrap;
          font-family: PingFang SC-Semibold, PingFang SC;  
          line-height: 0.38rem;
          flex: 0 0 auto;
          background: #D34A00;

          div{
            width: 100%;
          }

          div:nth-child(1){
            font-size: 0.28rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 0.33rem;
          }

          div:nth-child(2){
            font-size: 0.22rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 0.26rem;
          }
        }

        .select{
          font-size: 0.32rem;
          font-weight: 600;
          color: #903300;
          padding: 0.16rem 0;
          background: url("~@/assets/image/hodgepodge/tabbuttom.png") no-repeat;
          background-size: 100% 100%;


          div:nth-child(1){
            font-size: 0.32rem !important;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600 !important;
            color: #903300;
            line-height: 0.38rem !important;
          }

          div:nth-child(2){
            font-size: 0.26rem !important;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600 !important;
            color: #903300;
            line-height: 0.30rem !important;
          }
        }
      }
    }

    .viewsList{
      width: 100%;
      margin-top: 0.32rem;

      padding: 0.32rem;
      box-sizing:border-box ;


      ul{
        width: 100%;
        
        li{
          width: 100%;
          height: 2.48rem;
          background: #FFF8DE;
          box-shadow: 0px 0px 0.08rem 0px rgba(126,56,0,0.26); 
          border: 0.08rem solid #FFF0E1;
          border-radius: 0.32rem; 
          margin-bottom: 0.32rem;

          font-size: 0.48rem;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FF782F;
          line-height: 0.56rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  } 
}
</style>
